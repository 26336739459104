<template>
    <div class="py-4 bg-white bg-opacity-30 drkbg-slate-500 md:relative bottom-0 w-full animate__animated animate__fadeIn">
        <div class="text-center pt-2 text-sm">
            &copy;  Test Name 2023
            <!-- <div class="space-x-2 cursor-pointer">
                <i aria-hidden="true" class="fab fa-github"></i>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterComponent'
}
</script>

<style scoped>
    .animate__animated.animate__fadeIn {
        --animate-duration: 3s;
    }
</style>
