<template>
  <button style="z-index: 888888;" class="md:hidden float-left left-0 top-24 pl-2 fixed" @click="toggleMenu">
    <!-- Hamburger icon here -->
    <i class="fa fa-bars text-2xl text-blue-400 drktext-white" aria-hidden="true"></i>
  </button>
  
  <div :class="auth?'md:flex-rows':'md:grid-cols-1'" class="md:flex drkbg-slate-700 h-screen overflow-hidden">
    <div @mouseenter="thestyle='width: 14em'" @mouseleave="thestyle='width: 6em'">
    <SideBar :thestyle="thestyle" :style="thestyle" class=" h-screen hidden md:block "  v-if="auth"/>
    </div>
    <div @click="toggleMenu()" class="w-full bg-gray-100 bg-opacity-40 h-screen md:hidden top-0 left-0" :class="this.isOpen?'fixed':'hidden'"  v-if="auth">
      <SideBar @click.stop="" class="w-3/4 min-h-screen h-full md:hidden top-0 left-0" style="z-index: 50000;"/>
    </div>
    <div class="bg-mainbg bg-opacity-20 w-full h-full overflow-scroll  custom-scrollbar">
      <NavBar :style="'z-index: 100'"/>
      <router-view />
      <FooterComponent/>  
    </div>
  </div>
</template>

<script>

import FooterComponent from './components/Footer.vue'
import NavBar from './components/NavBar.vue'
import SideBar from './components/SideBarMenu.vue'
import get_api from "./service/api.js"
import base_url from "./service/base_url.js"


export default {
  name: 'App',
  created(){
    console.log("PORT", this.$route.path)
    this.api = get_api(this.$router)
    if (this.$route.path == "/"){
      this.show_sidebar = false
    } else {
      this.show_sidebar = true
    }
    if (localStorage.getItem("XPAYROLLaccess_token")){
      this.api.get(base_url+"/me").then((resp)=>{
        let data = resp.data.data
        if (data.is_admin){
          this.auth = true
        }
      })
    }
  },
  components: {
    FooterComponent,
    NavBar,
    SideBar
  },
  data(){
    return {
      thestyle: 'width: 6em',
      show_sidebar: false,
      isOpen: false,
      auth: false
    }
  },
  methods: {
    toggleMenu(){
       this.isOpen = !this.isOpen
      },
  }
}
</script>

<style scoped>
@font-face {
    font-family: bigdeal;
    src: url("@/assets/fonts/aBigDeal.ttf");
}

/* @font-face {
    font-family: kai;
    src: url("@/assets/fonts/kai.ttf");
}

* {
    font-family: kai;
  } */

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
}

/* nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
